import React, { useRef } from "react";
import "./css/Checkout.css";
import { TbBrandGoogle } from "../../Assets/google_color.js";
import menuicon from "../../Assets/menuicon.svg";
import { Link, useNavigate } from "react-router-dom";
import us from "../../Assets/Us.svg";
import { useState } from "react";
import { useEffect } from "react";
import forgotmodal from "../../Assets/forgotmodal.png";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/user";
import { toast } from "react-toastify";
import {
  getTaxes,
  getTotalCost,
  getTotalTickets,
  updateTicketCart,
} from "../../redux/ticket";
import { signupVerify } from "../../redux/user";
import store from "../../redux/store";
import validator from "validator";

import { LuCalendar } from "react-icons/lu";
import { GoDotFill } from "react-icons/go";
import { LuTicket } from "react-icons/lu";
import {
  decreaseQuantity,
  increaseQuantity,
  updateQuantity,
} from "../../redux/ticket";
import { Modal } from "react-bootstrap";
import OTPInput from "react-otp-input";
import axios from "axios";
import { MdKeyboardArrowDown, MdOutlineLocationOn } from "react-icons/md";
import { devApi } from "../../utils/constants";
import moment from "moment";
moment.suppressDeprecationWarnings = true;
export default function Checkout() {
  const token = useSelector((state) => state.user.token);
  //const eventDetails = useSelector((state) => state.ticket.eventDetails);
  const [cancelLoad, setCancelLoad] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });
  const [couponCode, setCouponCode] = useState();
  const [proceedLoading, setProceedLoading] = useState(false);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [checkoutSuccess, setCheckoutSuccess] = useState({
    mobileSuccess: false,
    emailSuccess: false,
    agreeSuccess: false,
  });
  const totalTickets = useSelector(getTotalTickets);
  const eventData = useSelector((state) => state.tickets.eventDetails);

  //signup variables
  const [signupdata, setSignupdata] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });

  const ticketTypes = useSelector((state) => state.tickets.ticketTypes);
  const taxes = useSelector(getTaxes);
  const totalCost = useSelector(getTotalCost);
  const updateData = (e) => {
    let { name, value } = e.target;
    setSignupdata({
      ...signupdata,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "" });
    // const isMatch = name === 'confirm_password' && signupdata.password === value;
    switch (name) {
      case "first_name":
        setErrors({
          ...errors,
          first_name: value.length === 0 ? "Name is required" : "",
        });
        break;
      case "email":
        setErrors({
          ...errors,
          email: value.length === 0 ? "Email is required" : "",
        });
        break;
      // case "confirm_password":setErrors({...errors,confirm_password: !isMatch ? "Password must not match the original password." : ""})
      // break;
      default:
        break;
    }
  };

  //useEffects
  useEffect(() => {
    if (!eventData) {
      isLoggedIn ? navigate("/") : navigate("/login");
    }

    //fetchEventDetails();
  }, []);

  const handleDecrease = (index) => {
    dispatch(decreaseQuantity({ index }));
  };
  const makePayment = async () => {
    setProceedLoading(true);

    var counts = {};
    const ticketData = ticketTypes.map((ticket) => {
      var key = `ticket_${ticket.id}`;
      counts = { ...counts, [key]: ticket.quantity };
      return ticket.id;
    });
    const body = {
      ...counts,
      event_id: eventData.data.event.event_id,
      tickets: ticketData,
    };
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };
    await axios
      .post(`${devApi}/api/e/postValidateTickets`, body, headers)
      .then((response) => {
        if (response.data.success) {
          // ReactGA.gtag("event", "begin_checkout", {
          //   value: response.data.data.order_total,
          //   currency: "USD",
          //   coupon: "",
          //   items: response.data.data.tickets.map((item) => ({
          //     item_id: item.ticket.id,
          //     item_name: item.title,
          //     affiliation: item.title,
          //     coupon: "",
          //     discount: 0,
          //     index: 0,
          //     item_brand: "DuPage County Fair",
          //     price: item.price,
          //     quantity: item.qty,
          //   })),
          // });
          store.dispatch(updateTicketCart(response.data.data));
          const config = {
            headers: {
              Authorization: "Bearer " + token,
              "Content-type": "application/json",
              Accept: "application/json",
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
            },
          };
          const body = {
            data: response.data.data,
            event_id: eventData.data.event.event_id,
            first_name: signupdata.first_name,
            last_name: signupdata.last_name,
            email: signupdata.email,
          };
          axios
            .post(`${devApi}/api/e/ticketPaymentCheckoutGuest`, body, config)
            .then(async (response) => {
              if (response.data.success) {
                if (response.data.order_type) {
                  navigate(
                    `/paymentSuccess?session_id=${response.data.order_id}`,
                    {
                      state: {
                        orderType: response.data.order_type,
                        orderId: response.data.order_id,
                      },
                    }
                  );
                } else window.location.replace(response.data.data.url);
              } else {
                setProceedLoading(false);
                setErrors({ ...errors, api: response.data.message });
              }
            })
            .catch((error) => {
              setProceedLoading(false);
              if (error.response.status === 450) {
                toast.error("Session Expired, Please log-in again");
                navigate("/login");
              }
            });
        } else {
          setErrors({ ...errors, api: response.data.message });
        }
      })
      .catch((error) => {
        setProceedLoading(false);
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });
  };
  const handleIncrease = (index) => {
    dispatch(increaseQuantity({ index }));
  };

  const handleQuantityChange = (event, index) => {
    const newQuantity = parseInt(event.target.value) || 0;
    dispatch(updateQuantity({ index, quantity: newQuantity }));
  };
  //functions-Handlers

  const handleCheckboxChange = (e) => {
    setCheckoutSuccess({
      ...checkoutSuccess,
      agreeSuccess: e.target.checked,
    });
  };

  if (!eventData) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  if (cancelLoad) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  if (proceedLoading) {
    return (
      <div className="d-flex flex-column justify-content-center text-center align-items-center ndf">
        {eventData.data.event.free_event == 0 ? (
          <>
            <span className="redirect-text ">
              You are being redirected to the secured payment gateway
            </span>
            <span>Do not click the "Back" button!</span>
          </>
        ) : (
          <></>
        )}
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <div style={{ background: "#F2F2F2" }}>
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center mt-2 w-100"
        style={{ backgroundColor: "white", borderBottom: "0.5px solid gray" }}
      >
        <h5 className="event-title-checkout">{eventData.data.event.title}</h5>
        <h5 className="event-location-checkout justify-content-center align-items-center mx-2">
          {eventData.data.event.venue_name},
          {eventData.data.event.location_address_line_1},
          {eventData.data.event.location_address_line_2},{" "}
          {eventData.data.event.location_post_code}
        </h5>
        <div className="d-flex flex-row justify-content-center">
          <h6 className="event-location-checkout">
            {eventData.data.event.startday}
          </h6>
          <GoDotFill
            className="mx-2"
            style={{ width: "10px", height: "10px", color: "#121212" }}
          />

          <h6 className="event-location-checkout">
            {moment(eventData.data.event.start_date).format("hh:mm A")}
          </h6>
        </div>
      </div>
      <div className="container bgContainer">
        <div className="d-flex flex-sm-row flex-column justify-content-between mt-sm-5 my-0">
          <div className="d-flex d-sm-none justify-content-between flex-column select-ticket my-2 divBg">
            <h5>Choose Tickets</h5>
            <div className="ticket-wrap">
              {ticketTypes.map((ticketType, index) => (
                <div
                  className="d-flex align-items-center justify-content-between my-2"
                  key={index}
                >
                  <div className="d-flex align-items-start">
                    <LuTicket size={20} color={"#024DDF"} />
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="pass-type ms-2">{ticketType.type}</h6>
                      {eventData.data.event.event_id == 34 ? (
                        <h4 className="earlyBirdText">
                          {ticketType.description}
                        </h4>
                      ) : (
                        <></>
                      )}
                      {ticketType.isEarlyBird ? (
                        <h4 className="earlyBirdText">(Early Bird Offer)</h4>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-row  justify-content-around text-center align-items-center price-quantity">
                    {ticketType.isEarlyBird ? (
                      <div className="d-flex justify-content-between text-center align-items-center">
                        <h6 className="paycharge-early">
                          $ {ticketType.price}
                        </h6>{" "}
                        <del className="ms-1">
                          <h6 className="text-secondary">
                            $ {ticketType.full_price}
                          </h6>
                        </del>
                      </div>
                    ) : eventData.data.event.free_event == 0 ? (
                      <h6 className="paycharge">$ {ticketType.price}</h6>
                    ) : (
                      <></>
                    )}
                    <div className="quantity ms-1">
                      <button
                        className="minus"
                        onClick={() => handleDecrease(index)}
                        disabled={ticketType.quantity <= 0}
                      >
                        -
                      </button>
                      <input
                        className="input-box"
                        type="number"
                        value={ticketType.quantity}
                        disabled={true}
                        onChange={(event) => handleQuantityChange(event, index)}
                      />
                      <button
                        className="plus"
                        disabled={
                          eventData.data.event.free_event == 1 &&
                          ticketType.quantity >= 30
                        }
                        onClick={() => handleIncrease(index)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-sm-6 col-12 d-flex flex-column">
            <div
              className=" mt-0 event-order-box"
              style={{ backgroundColor: "white" }}
            >
              <h4 className="cart-heading">Contact Info</h4>
              <div className="d-flex  mb-2 mt-2">
                <div
                  className="d-inline flex-column mb-2 "
                  style={{ width: "50%" }}
                >
                  <label htmlFor="" className="signup-label">
                    First Name<small style={{ color: "red" }}>*</small>
                  </label>
                  <input
                    type="text"
                    className="signup-input-name"
                    placeholder="Enter First Name"
                    name="first_name"
                    onChange={updateData}
                    value={signupdata.first_name}
                  />
                  <small className="text-danger ml-2">
                    {errors.first_name}
                  </small>
                </div>
                <div
                  className="d-inline flex-column mb-2 ms-2"
                  style={{ width: "50%" }}
                >
                  <label for="formLastName" className="signup-label">
                    Last Name<small style={{ color: "red" }}>*</small>
                  </label>
                  <input
                    type="text"
                    id="formLastName"
                    className="signup-input-name"
                    placeholder="Enter Last Name"
                    name="last_name"
                    onChange={updateData}
                    value={signupdata.last_name}
                  />
                  <small className="text-danger ml-2">{errors.last_name}</small>
                </div>
              </div>

              <div className="mb-2">
                <label htmlFor="" className="signup-label">
                  Mobile Number
                </label>
                <div className="d-flex">
                  {" "}
                  <div
                    className="form-input4 text-center align-items-center"
                    style={{ padding: "12px 12px" }}
                  >
                    <img
                      src={us}
                      alt=""
                      className="img-fluid flag-img me-1"
                      style={{
                        width: "24px",
                        height: "23px",
                        marginBottom: 0,
                      }}
                    />{" "}
                    <small className="me-1 align-items-center">+1</small>
                  </div>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    className="signup-input ms-1"
                    onChange={updateData}
                    placeholder="Enter your mobile number"
                    value={signupdata.phone}
                  />
                </div>
                <small className="text-danger ml-2">{errors.phone}</small>
              </div>

              <div className="mb-2">
                <label htmlFor="" className="signup-label">
                  Email<small style={{ color: "red" }}>*</small>
                </label>
                <input
                  type="text"
                  className="signup-input"
                  placeholder="Enter Email"
                  name="email"
                  onChange={updateData}
                  value={signupdata.email}
                />
                <small className="text-danger ml-2">{errors.email}</small>
              </div>
            </div>
            <div className=" mt-4">
              <div
                className="d-flex terms-condition justify-content-between "
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
                style={{ backgroundColor: "white" }}
              >
                <h6 className="mx-3 mt-4">Terms and Conditions</h6>
                <MdKeyboardArrowDown
                  className="mx-3 mt-4"
                  style={{ width: "24px", height: "24px", color: "#024DDF" }}
                />
              </div>

              <div
                className="collapse terms-body"
                id="collapseExample"
                style={{ backgroundColor: "white" }}
              >
                <div
                  className="terms-body terms-text card-body mx-2"
                  style={{ backgroundColor: "white" }}
                >
                  <ul>
                    <li>
                      Cancellation Policy: Tickets are NON-TRANSFERABLE and
                      NON-REFUNDABLE unless the event is canceled or
                      rescheduled.
                    </li>
                    <li>
                      Refund Policy: Refunds are offered only if an event is
                      canceled, rescheduled, or moved. Refunds go back to the
                      original payment mode.
                    </li>
                    <li>
                      In case of event cancellation by the organizer, platform
                      fees and processing fees are NON-REFUNDABLE.
                    </li>
                    <li>
                      If you have any concerns about event cancellations or
                      refunds, please contact the event organizer directly. Only
                      genuine mobile tickets with QR codes will be accepted for
                      entry.
                    </li>
                    <li>
                      Arrive at the event at least 30 minutes prior to avoid
                      rush at the check-in counter.
                    </li>
                    <li>
                      A standard admission ticket provides a single entry to the
                      venue. Once you leave, you cannot re-enter using the same
                      ticket.
                    </li>
                    <li>
                      The tickets purchased are subject to Eventgem’s Terms and
                      Conditions, Privacy Policy, and Cookie Policy.
                    </li>
                    <li>
                      Privacy Policy: Users must review and agree to the privacy
                      policy, which details data handling and security.
                    </li>
                    <li>
                      Prohibited Activities: Users are prohibited from engaging
                      in illegal activities, violating intellectual property
                      rights, or using the service for unauthorized commercial
                      purposes.
                    </li>
                    <li>
                      Disclaimer: Services are provided "as is" without any
                      warranties. Eventgem is not responsible for any
                      third-party links or services.
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="d-flex  terms-accept align-items-center "
                style={{ backgroundColor: "white" }}
              >
                <input
                  className="form-check-input checkbox-inline ms-3 my-3"
                  type="checkbox"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="option1"
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label acceptText ms-2 my-3"
                  for="inlineRadio1"
                >
                  By clicking here, I state that I have read and understood the
                  "Terms and Conditions".
                </label>
              </div>
              <div className="d-sm-flex d-none justify-content-start mt-4 ">
                <button
                  className="btn btn-secondary me-2"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="col-sm-4 col-12">
            <div
              className="d-flex justify-content-between flex-column order-summary-div mt-sm-0 mt-3 divBg"
              style={{ backgroundColor: "white" }}
            >
              <h5>Order Summary</h5>
              <div className="mobile">
                {ticketTypes.map((ticketType, index) => (
                  <div
                    className="d-flex align-items-center justify-content-between my-4"
                    key={index}
                  >
                    <div className="d-flex align-items-center">
                      <LuTicket size={20} color={"#024DDF"} />
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="pass-type ms-2">{ticketType.type}</h6>
                        {eventData.data.event.event_id == 34 ? (
                          <h4 className="earlyBirdText">
                            {ticketType.description}
                          </h4>
                        ) : (
                          <></>
                        )}
                        {ticketType.isEarlyBird ? (
                          <h4 className="earlyBirdText">(Early Bird Offer)</h4>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-end text-center align-items-center price-quantity">
                      {ticketType.isEarlyBird ? (
                        <div className="d-flex justify-content-between text-center align-items-center">
                          <h6 className="paycharge-early">
                            $ {ticketType.price}
                          </h6>{" "}
                          <del className="ms-1">
                            <h6 className="text-secondary">
                              $ {ticketType.full_price}
                            </h6>
                          </del>
                        </div>
                      ) : eventData.data.event.free_event == 0 ? (
                        <h6 className="paycharge">$ {ticketType.price}</h6>
                      ) : (
                        <></>
                      )}
                      <div className="quantity ms-2">
                        <button
                          className="minus"
                          onClick={() => handleDecrease(index)}
                          disabled={ticketType.quantity <= 0}
                        >
                          -
                        </button>
                        <input
                          className="input-box"
                          type="number"
                          value={ticketType.quantity}
                          disabled={true}
                          onChange={(event) =>
                            handleQuantityChange(event, index)
                          }
                        />
                        <button
                          className="plus"
                          disabled={
                            eventData.data.event.free_event == 1 &&
                            ticketType.quantity >= 30
                          }
                          onClick={() => handleIncrease(index)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-sm-none justify-content-center align-items-center">
                {ticketTypes.map((ticketType, index) =>
                  ticketType.quantity >= 1 ? (
                    <div
                      className="d-flex align-items-center justify-content-between my-4"
                      key={index}
                    >
                      <h6 style={{ color: "blue", fontSize: "14px" }}>
                        {ticketType.type} x ({ticketType.quantity})
                      </h6>
                      <h6 style={{ color: "blue", fontSize: "14px" }}>
                        $ {(ticketType.price * ticketType.quantity).toFixed(2)}
                      </h6>
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </div>
              {eventData.data.event.free_event == 0 ? (
                <>
                  {" "}
                  <div className="d-flex justify-content-between align-items-center convcharge mb-2">
                    <p>Processing Fee</p>
                    <p> $ {taxes.processingFee.toFixed(2)}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center convcharge mb-2">
                    <p>Platform Fee</p>
                    <p> $ {taxes.bookingFee.toFixed(2)}</p>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between align-items-center my-3">
                    <h6 className="amountpay">Amount Payable</h6>
                    <h4 className="totalpay">
                      ${" "}
                      {(
                        totalCost +
                        taxes.processingFee +
                        taxes.bookingFee
                      ).toFixed(2)}
                    </h4>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* <div
              className="d-flex justify-content-between flex-column order-summary-div mt-sm-2 mt-3 divBg"
              style={{ backgroundColor: "white" }}
            >
              <h5>Coupon</h5>
              <div className="d-flex flex-row">
                <input
                  className="form-control"
                  placeholder="Enter coupon code"
                  value={couponCode}
                  onChange={setCouponCode}
                ></input>
                <button
                  className={
                    couponCode
                      ? "btn btn-primary ms-2"
                      : "btn btn-secondary ms-2"
                  }
                  disabled={!couponCode}
                >
                  Apply
                </button>
              </div>
            </div>*/}
            <div className="d-flex flex-row justify-content-center align-items-center my-4">
              {eventData.data.event.free_event == 0 ? (
                <button
                  className={
                    !checkoutSuccess.agreeSuccess ||
                    totalTickets == 0 ||
                    !signupdata.email ||
                    !signupdata.first_name ||
                    !signupdata.last_name
                      ? "btn btn-secondary"
                      : "btn btn-primary"
                  }
                  style={{ width: "90%" }}
                  disabled={
                    totalTickets == 0 ||
                    totalCost == 0 ||
                    !checkoutSuccess.agreeSuccess ||
                    !signupdata.email ||
                    !signupdata.first_name ||
                    !signupdata.last_name
                  }
                  onClick={makePayment}
                >
                  Pay Now
                </button>
              ) : (
                <button
                  className={
                    !checkoutSuccess.agreeSuccess ||
                    totalTickets == 0 ||
                    !signupdata.email ||
                    !signupdata.first_name ||
                    !signupdata.last_name
                      ? "btn btn-secondary"
                      : "btn btn-primary"
                  }
                  style={{ width: "90%" }}
                  disabled={
                    totalTickets == 0 ||
                    !checkoutSuccess.agreeSuccess ||
                    !signupdata.email ||
                    !signupdata.first_name ||
                    !signupdata.last_name
                  }
                  onClick={makePayment}
                >
                  Register
                </button>
              )}
            </div>

            {errors.api ? (
              <small className="text-danger ml-2">{errors.api}</small>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
