import React from "react";
import "./css/Banner.css";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaInstagram } from "react-icons/fa6";
import { TbBolt } from "react-icons/tb";
import { LuCalendar } from "react-icons/lu";
import { GoDotFill } from "react-icons/go";
import { LuTicket } from "react-icons/lu";
import { RiWallet3Line } from "react-icons/ri";
import { IoMdArrowForward, IoMdAlarm } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import menuicon from "../../Assets/menuicon.svg";
import midSept from "../../Assets/bloomMidSept.png";
import midOct from "../../Assets/bloomMidOct.png";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import {
  decreaseQuantity,
  increaseQuantity,
  updateQuantity,
  getTotalTickets,
  getTotalCost,
  updateTicketCart,
} from "../../redux/ticket";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Banner({ data, ticketOrder }) {
  var settings = {
    dots: true,
    infinite: false,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    // variableWidth: false,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 420,
        settings: {
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: false,
        },
      },
    ],
  };
  const navigate = useNavigate();
  const ticketTypes = useSelector((state) => state.tickets.ticketTypes);
  const totalCost = useSelector(getTotalCost);
  const totalTickets = useSelector(getTotalTickets);
  const dispatch = useDispatch();
  useEffect(() => {}, []);
  const handleBuyNow = (e) => {
    e.preventDefault();
    ticketOrder.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const handleDecrease = (index) => {
    dispatch(decreaseQuantity({ index }));
  };

  const handleIncrease = (index) => {
    dispatch(increaseQuantity({ index }));
  };

  const handleQuantityChange = (event, index) => {
    const newQuantity = parseInt(event.target.value) || 0;
    dispatch(updateQuantity({ index, quantity: newQuantity }));
  };
  const handleCartCheckout = async () => {
    navigate("/checkout");
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-between my-3"></div>
        <div className="col-lg-12">
          <div className="slide-main-box">
            <Slider className="banner-image" {...settings}>
              {data.data.banners?.map((image, index) => {
                return (
                  <img
                    id={index}
                    src={image.banner_img_fullpath}
                    alt=""
                    className="img-fluid"
                  />
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <div className="row justify-content-between my-5">
        {/*Buy Now Fixed Bottom for Mobile */}
        <div
          className="navbar fixed-bottom navbar-light bg-light  justify-content-between d-sm-none"
          style={{ boxShadow: " 0 0 15px -6px gray", height: "70px" }}
        >
          <div className="d-flex flex-row align-items-center justify-content-between mx-2">
            {data.data.event.free_event == 0 ? (
              <>
                {" "}
                <RiWallet3Line size={24} color={"#000000"} />
                <h5 className="ticket-price-mobile ms-2">
                  ${data.data.tickets[0].price}
                </h5>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center mx-2">
            {data.data.event.free_event == 0 ? (
              <button className="btn btn-primary" onClick={handleCartCheckout}>
                Buy Now
              </button>
            ) : (
              <button className="btn btn-primary" onClick={handleCartCheckout}>
                Register
              </button>
            )}
          </div>
        </div>
        {/*Buy Now Fixed Bottom for Mobile Ends*/}
        <div className="col-lg-7 datas-box">
          <div className="d-flex align-items-center mb-4">
            <h3 className="title">{data.data.event.title}</h3>
            {data.data.event.isFillingFast ? (
              <div className="filling-tab">
                <TbBolt
                  style={{ width: "24px", height: "24px", color: "#121212" }}
                />
                <h4>Filling Fast</h4>
              </div>
            ) : (
              ""
            )}
          </div>
          {/*Event details for Mobile */}
          <div className="d-flex justify-content-start flex-column align-items-start ticket-wrap mt-3 d-sm-none">
            {" "}
            <div className="d-flex align-items-center mb-3">
              <div className="icon-box me-3">
                <img src={menuicon} style={{ width: "24px", height: "24px" }} />
              </div>
              <h6 className="info">{data.data.event.categoryName}</h6>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="icon-box me-3">
                <LuCalendar
                  style={{ width: "24px", height: "24px", color: "#121212" }}
                />
              </div>
              <h6 className="info">{data.data.event.startday}</h6>
              <div className="mx-2">
                <GoDotFill
                  style={{ width: "10px", height: "10px", color: "#121212" }}
                />
              </div>
              <h6 className="info">
                {moment(data.data.event.start_date).format("hh:mm A")}
              </h6>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="icon-box me-3">
                <MdOutlineLocationOn
                  style={{ width: "24px", height: "24px", color: "#121212" }}
                />
              </div>
              <h6 className="info">{data.data.event.venue_name}</h6>
            </div>
          </div>
          {/*Event details for Mobile Ends*/}
          <div className="d-sm-flex align-items-center bidding-offer d-none">
            {data.data.event.early_bird ? (
              <>
                {" "}
                <IoMdAlarm
                  style={{ width: "24px", height: "24px", color: "#23BA20" }}
                />
                <h4>Early Bird Offer </h4>
                <span>( Ends on {data.data.event.early_bird_end_date})</span>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="info-data mobile">
            <h5>About</h5>
            <p>{data.data.event.description}</p>
            {data.data.event.event_id == 34 ? (
              <div className="d-flex flex-row justify-content-between">
                <img src={midSept} className="bloomImages mx-2" />
                <img src={midOct} className="bloomImages mx-2" />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="info-data">
            <h5>Venue</h5>
            <p>
              {data.data.event.venue_name}
              {/* {data.data.event.location_address}  {data.data.event.location_address_line_1} {data.data.event.location_address_line_2} */}
              <br />
              {data.data.event.location_address_line_1} <br />
              {data.data.event.location_address_line_2},{" "}
              {data.data.event.location_post_code}
            </p>
          </div>
          <div className="direction-btn">
            Get Directions
            <IoMdArrowForward style={{ width: "24px", height: "24px" }} />
          </div>
          <div className="info-data d-sm-none mt-2">
            <h5>About</h5>
            <p>{data.data.event.description}</p>
            {data.data.event.event_id == 34 ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={midSept} className="bloomImages" />
                <img src={midOct} className="bloomImages" />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {data.data.tickets[0].is_paused ? (
          <></>
        ) : (
          <div className="col-lg-4">
            <div className="d-sm-flex d-none justify-content-center align-items-sm-start align-items-center flex-column ">
              {/*<button className="buynow-btn" onClick={handleBuyNow}>
              Buy Now
            </button>*/}

              <div className="d-flex justify-content-between flex-column select-ticket mt-2">
                <h5>Choose Tickets</h5>
                <div className="ticket-wrap">
                  {ticketTypes.map((ticketType, index) => (
                    <div
                      className="d-flex align-items-center justify-content-between my-4"
                      key={index}
                    >
                      <div className="d-flex align-items-center">
                        <LuTicket size={20} color={"#024DDF"} />
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="pass-type ms-2">{ticketType.type}</h6>
                          {data.data.event.event_id == 34 ? (
                            <h4 className="earlyBirdText">
                              {ticketType.description}
                            </h4>
                          ) : (
                            <></>
                          )}
                          {ticketType.isEarlyBird ? (
                            <h4 className="earlyBirdText">
                              (Early Bird Offer)
                            </h4>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="d-flex flex-sm-row flex-column justify-content-end text-center align-items-center price-quantity">
                        {ticketType.isEarlyBird ? (
                          <div className="d-flex justify-content-between text-center align-items-center">
                            <h6 className="paycharge-early">
                              $ {ticketType.price}
                            </h6>{" "}
                            <del className="ms-1">
                              <h6 className="text-secondary">
                                $ {ticketType.full_price}
                              </h6>
                            </del>
                          </div>
                        ) : data.data.event.free_event == 0 ? (
                          <h6 className="paycharge">$ {ticketType.price}</h6>
                        ) : (
                          <></>
                        )}
                        <div className="quantity ms-2 ">
                          <button
                            className="minus"
                            onClick={() => handleDecrease(index)}
                            disabled={ticketType.quantity <= 0}
                          >
                            -
                          </button>
                          <input
                            className="input-box"
                            type="number"
                            disabled={true}
                            value={ticketType.quantity}
                            onChange={(event) =>
                              handleQuantityChange(event, index)
                            }
                          />
                          <button
                            className="plus"
                            onClick={() => handleIncrease(index)}
                            disabled={
                              data.data.event.free_event == 1 &&
                              ticketType.quantity >= 30
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center mt-4">
                  {data.data.event.free_event == 0 ? (
                    <button
                      className={
                        totalTickets ? "btn btn-primary" : "btn btn-secondary"
                      }
                      style={{ width: "90%" }}
                      disabled={!totalTickets}
                      onClick={handleCartCheckout}
                    >
                      Add to cart
                    </button>
                  ) : (
                    <button
                      className={
                        totalTickets ? "btn btn-primary" : "btn btn-secondary"
                      }
                      style={{ width: "90%" }}
                      disabled={!totalTickets}
                      onClick={handleCartCheckout}
                    >
                      Register
                    </button>
                  )}
                </div>
              </div>

              <div className="d-sm-flex d-none justify-content-start flex-column align-items-start mt-3 ">
                {" "}
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-box me-3">
                    <img
                      src={menuicon}
                      style={{ width: "24px", height: "24px" }}
                    />
                  </div>
                  <h6 className="info">{data.data.event.categoryName}</h6>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-box me-3">
                    <LuCalendar
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#121212",
                      }}
                    />
                  </div>
                  <h6 className="info">{data.data.event.startday}</h6>
                  <div className="mx-2">
                    <GoDotFill
                      style={{
                        width: "10px",
                        height: "10px",
                        color: "#121212",
                      }}
                    />
                  </div>
                  <h6 className="info">
                    {moment(data.data.event.start_date).format("hh:mm A")}
                  </h6>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-box me-3">
                    <MdOutlineLocationOn
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#121212",
                      }}
                    />
                  </div>
                  <h6 className="info">{data.data.event.venue_name}</h6>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="container">
        {/*<div className=" d-flex flex-sm-row flex-column justify-content-between my-4">*/}
        <div className=" d-flex flex-column justify-content-between my-4">
          {data.data.event.event_details_temp ? (
            <div className="d-flex  flex-column col-lg-8 col-md-12 col-sm-12 col-12 my-3">
              <h5 className="org-head">Know the Artist</h5>
              <div className="d-flex  flex-column mt-4">
                <div className="artist-detail">
                  <div className="row d-flex align-items-center">
                    <div className="d-flex flex-row align-items-center artist-img">
                      <img src={data.data.artists.img_full_path} alt="" />
                      <div className=" artist-info ms-3">
                        <h6>{data.data.artists.categoryName}</h6>
                        <h4>{data.data.artists.name}</h4>
                      </div>
                    </div>

                    <div className="col-12 artist-desc mt-3">
                      <h6>About Artist</h6>
                      <p>
                        {" "}
                        {data.data.artists.description === ""
                          ? `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.`
                          : data.data.artists.description}
                      </p>
                    </div>

                    <div className="col-12 d-flex justify-content-center flex-column">
                      <h6 className="artist-follow-head text-center mt-4">
                        Follow on
                      </h6>
                      <div className="artist-follow-icon mt-1">
                        <Link to={data.data.artists.insta_link} target="_blank">
                          <FaInstagram
                            style={{
                              width: "32px",
                              height: "32px",
                              color: "#024DDF",
                            }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="d-flex flex-column col-lg-8 col-md-12 col-sm-12 col-12 my-3">
            <h2 className="org-head">Organizer Details</h2>
            <div className="org-box mt-4">
              <div className="d-flex align-items-start justify-content-between w-100">
                <div className=" d-flex align-items-center">
                  <img src={data.data.organiser[0].logo_path_fullpath} alt="" />
                  <h6>{data.data.organiser[0].name}</h6>
                </div>
              </div>
              {data.data.organiser[0].about ? (
                <>
                  <h5>About</h5>
                  <p>{data.data.organiser[0].about}</p>
                </>
              ) : (
                <></>
              )}
              <div className=" d-flex justify-content-center align-items-center flex-column w-100">
                <h4 className="artist-follow-head text-center mt-4">
                  Follow on
                </h4>
                <div className="artist-follow-icon mt-1">
                  <Link
                    to={data.data.organiser[0].instagram_url}
                    target="_blank"
                  >
                    <FaInstagram
                      style={{
                        width: "32px",
                        height: "32px",
                        color: "#024DDF",
                      }}
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <Link to="/organiserT&C">
                <button className="direction-btn">
                  Terms and Conditions{" "}
                  <IoMdArrowForward style={{ width: "24px", height: "24px" }} />
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/*{data.data.sponsors.length ? (
            <div className="d-flex flex-column  col-sm-4 col-12 my-4">
              <h5 className="org-head">Our Sponsors</h5>
              <div className="d-flex flex-wrap align-items-center justify-content-center artist-detail mt-4 ">
                {data.data.sponsors.map((sponsor, index) => {
                  return (
                    <div>
                      <img
                        id={index}
                        src={sponsor.sponsor_img_fullpath}
                        alt=""
                        className=" sponsorImages mx-1"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>*/}
      </div>
    </div>
  );
}
